
import {
  defineComponent,
  ref,
  unref,
  toRefs,
  onMounted,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
  ElMessage,
  ElForm,
  ElNotification,
  ElMessageBox
} from 'element-plus';
import { UploadFile } from 'element-plus/es/components/upload/src/upload.type';
import dayjs from 'dayjs';

import ImageUploader, { UploadImageEvent } from '@/components/ImageUploader.vue';
import VideoUploader, { UploadEvent } from '@/components/video-uploader/Index.vue';
import {
  getTags,
  getDirectors,
  uploadVideo,
  MAX_PER_PAGE,
  IntBoolean
} from '@/services/api';
import { useCreateVideo, useUpdateVideo, useVideo } from '@/composables/api';
import { PartialVideo, VideoStatus, VideoSourceType } from '@/interfaces/Video';
import { Tag } from '@/interfaces/Tag';
import { Director } from '@/interfaces/Director';
import useFormErrors from '@/utils/validate';
import { useI18n } from 'vue-i18n';

const DEFAULT_FORM_VALUES: PartialVideo & Partial<{
   directorIds: number[]
  }> = {
    serialNumber: '',
    videoDuration: 0,
    cover: '',
    coverSource: '',
    coverLink: '',
    previewVideo: '',
    previewVideoLink: '',
    source: VideoSourceType.CDN77,
    video: '',
    videoLink: '',
    videoSource: '',
    videoSourceLink: '',
    title: '',
    description: '',
    publishedAt: '',
    status: VideoStatus.DRAFT,
    directorIds: [],
    modelTags: [],
    studioTags: [],
    genreTags: [],
    sourceId: 0,
    views: 0,
    isFree: IntBoolean.FALSE,
    isMain: IntBoolean.FALSE
  };

const rules = {
  // serialNumber: [
  //   {
  //     required: true,
  //     trigger: 'blur'
  //   }
  // ],
  // cover: [
  //   {
  //     required: true,
  //     trigger: 'blur'
  //   }
  // ],
  // previewVideo: [
  //   {
  //     required: true,
  //     trigger: 'change'
  //   }
  // ],
  videoSource: [
    {
      required: true,
      trigger: 'change'
    }
  ],
  title: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  description: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  sourceId: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  publishedAt: [
    {
      required: true,
      trigger: 'blur'
    }
  ],
  views: [
    {
      required: true,
      trigger: 'blur'
    }
  ]
};

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ImageUploader,
    VideoUploader
  },
  setup(props) {
    const { isEdit } = toRefs(props);
    const videoId = useRoute().params.id as string;
    const page = useRoute().query.page as string;
    const router = useRouter();

    const formRef = ref<typeof ElForm>();
    const formValues = ref({ ...DEFAULT_FORM_VALUES });
    const { formErrors, bindFormItemError } = useFormErrors();
    const { t } = useI18n();

    const previewFileList = ref([]);
    watch(() => formValues.value.previewVideo, (previewVideo) => {
      previewFileList.value = previewVideo
        ? [{ name: previewVideo }]
        : [];
    });

    // format publish at
    watch(() => formValues.value.publishedAt, (publishedAt) => {
      formValues.value.publishedAt = dayjs(publishedAt).format('YYYY-MM-DD HH:mm:ss');
    });

    const directors = ref<Director[]>([]);

    const { isLoading: isCreatedLoading, mutate: create } = useCreateVideo();
    const { isLoading: isUpdatedLoading, mutate: update } = useUpdateVideo();

    const fetchDirectors = async() => {
      directors.value = (await getDirectors()).data;
    };

    const tags = ref<Tag[]>([]);
    const modelTags = ref<Tag[]>([]);
    const studioTags = ref<Tag[]>([]);
    const genreTags = ref<Tag[]>([]);

    async function fetchTags() {
      try {
        const response = await getTags({ query: { perPage: MAX_PER_PAGE } });
        tags.value = response.data;

        studioTags.value = tags.value.filter(tag => tag.type === 'studio');
        modelTags.value = tags.value.filter(tag => tag.type === 'model');
        genreTags.value = tags.value.filter(tag => tag.type === 'genre');
      } catch (error) {
        console.error('Fetching tags failed:', error);
      }
    }

    const { data, dataUpdatedAt } = useVideo({ videoId }, {
      enabled: isEdit.value,
      refetchOnWindowFocus: !isEdit.value
    });

    watch(dataUpdatedAt, () => {
      formValues.value = data.value.data;
    });

    onMounted(async() => {
      fetchDirectors();
      fetchTags();
    });

    const handleCoverCrop = ({ path }: UploadImageEvent) => {
      formValues.value.cover = path;
    };

    const handlePreviewVideoRemove = () => {
      formValues.value.previewVideo = '';
    };

    const handlePreviewVideoChange = async({ raw }: UploadFile) => {
      if (raw.size < 5242880) {
        const { data } = await uploadVideo({ data: { video: raw } });

        formValues.value.previewVideo = data.path;
      } else {
        ElMessageBox.confirm(
          'File above 5MB cannot be uploaded',
          'Warning',
          {
            confirmButtonText: 'OK',
            showCancelButton: false,
            type: 'warning'
          }
        );

        previewFileList.value = previewFileList.value.slice(-1);
      }
    };

    const handleVideoUpload = ({ path }: UploadEvent) => {
      formValues.value.videoSource = path;
    };

    const handleVideoError = () => {
      ElNotification({
        title: 'Warning',
        message: 'Failed to upload video. Please try again.',
        type: 'warning'
      });
    };

    const submitForm = () => {
      formErrors.value = {};
      const form = unref(formRef);

      form && form.validate((valid: boolean) => {
        if (valid) {
          if (isEdit.value) {
            update({ videoId, data: formValues.value }, {
              onSuccess() {
                ElMessage.success({
                  message: 'success!',
                  type: 'success'
                });

                router.push({
                  name: 'list-videos'
                });
              },
              onError(error: any) {
                ElMessage.error(error.response?.data.message);
                formErrors.value = error.response?.data.errors;
              }
            });

            return;
          }

          create({ data: formValues.value }, {
            onSuccess() {
              ElMessage('success!');

              formValues.value = DEFAULT_FORM_VALUES;
              router.push({
                name: 'list-videos'
              });
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
              formErrors.value = error.response?.data.errors;
            }
          });
        }
      });
    };

    return {
      formRef,
      formValues,
      previewFileList,
      directors,
      tags,
      rules,
      IntBoolean,
      page,
      modelTags,
      studioTags,
      genreTags,
      handleCoverCrop,
      handlePreviewVideoChange,
      handlePreviewVideoRemove,
      handleVideoUpload,
      handleVideoError,
      VideoStatus,
      VideoSourceType,
      isCreatedLoading,
      isUpdatedLoading,
      submitForm,
      formErrors,
      t,
      bindFormItemError
    };
  },
  computed: {
    videoSourceRules() {
      const rules = [];
      rules.push({
        required: false,
        message: 'Please upload a video source',
        trigger: 'change'
      });
      return rules;
    }
  }
});

